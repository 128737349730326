<template>
  <div class="container">
    <el-row class="row shadow" type="flex" justify="space-around">
      <el-col :span="12"> <TimeZone @change="dataChange" @changeDateZone="selectDateZone"></TimeZone></el-col>
      <el-col :span="6">
        <el-select
          class="selector"
          v-model="appName"
          filterable
          placeholder="Please choose app protocol"
          @change="appChange"
        >
          <el-option v-for="item in appOption" :key="item.value" :label="item.label" :value="item.value"> </el-option>
        </el-select>
      </el-col>
      <el-col :span="6">
        <el-autocomplete
          class="inline-input"
          v-model="clientIp"
          placeholder="Please input IP address"
          :fetch-suggestions="searchIpList"
          :select-when-unmatched="true"
          clearable
          @select="searchByIp"
        >
          <!-- <el-button slot="append" icon="el-icon-search" @click="searchByIp"></el-button> -->
        </el-autocomplete>
        <!-- <el-input v-model="clientIp" placeholder="Please input IP address">
          
        </el-input> -->
      </el-col>
    </el-row>
    <el-row v-if="FlowLineDataByClient.length">
      <el-col :span="24" class="col line shadow">
        <Chart
          chartId="se1cond_line_client shadow"
          :option="
            new LineOption({
              title: `${appName} Traffic of IP ${clientIp} As Client from ${apiOptionsMap[baseURL]}`,
              legendTitle: [line_head.name],
              ipVersion,
              keepPoint: 4,
            }).getOption(['title', 'tooltip', 'xAxis', 'yAxis', 'grid', 'series', 'legend'])
          "
          :series="FlowLineDataByClient"
        ></Chart>
      </el-col>
    </el-row>

    <el-row v-if="FlowLineDataByServer.length">
      <el-col :span="24" class="col line shadow">
        <Chart
          chartId="se1cond_line_server shadow"
          :option="
            new LineOption({
              title: `${appName} Traffic of IP ${clientIp} As Server from ${apiOptionsMap[baseURL]}`,
              legendTitle: [line_head.name],
              ipVersion,
              keepPoint: 4,
            }).getOption(['title', 'tooltip', 'xAxis', 'yAxis', 'grid', 'series', 'legend'])
          "
          :series="FlowLineDataByServer"
        ></Chart>
      </el-col>
    </el-row>
    <div class="table-box shadow">
      <PaginationTable
        :loading="loading"
        :originData="flowTable"
        :showRule="showRule"
        :defaultOrder="{ prop: 'Src_to_Dst_bps', order: 'descending' }"
      ></PaginationTable>
    </div>
  </div>
</template>

<script>
import TimeZone from '@/components/TimeZone'
// import Chart from '@/submodules/components/Chart'
// import PaginationTable from '@/components/PaginationTable'
import { getAppList } from '@/api/AppApi.js'
import { getIpListByApp } from '@/api/IpApi.js'
import { getFlowDetailTable, getFlowDetailLine } from '@/api/FlowApi.js'
import { FlowLineOption } from '@/templates/chart/lineTemplate'
import { formatData } from '@/utils/dateHandler.js'
import DynamicRoute from '@/components/DynamicRoute'
import NumberCol from '@/components/NumberCol'
// import testJSON from '@/api/1.json'
export default {
  name: 'FlowView',
  components: {
    TimeZone,
    // Chart,
    // PaginationTable,
  },
  created() {
    this.ipVersion = localStorage.getItem('ip_version') || 'nf'

    // this.defalutdateArray = this.$route.query.date
    if (this.$route.query) {
      if (this.$route.query.appName) {
        this.appName = this.$route.query.appName
      }
      if (this.$route.query.baseUrl) {
        this.baseURL = this.$route.query.baseUrl
      }
      // if (this.$route.query.protocolType) {
      //   this.protocolType = this.$route.query.type
      // }
      if (this.$route.query.ip) {
        this.clientIp = this.$route.query.ip
      }
    }
  },
  data() {
    return {
      ipListLimit: 100,
      loading: false,
      ipVersion: 'nf',
      // defalutdateArray: [],
      LineOption: FlowLineOption,
      dateArray: [],
      appName: '',
      baseURL: '/api',
      clientIp: '',
      appOption: [],
      ipOption: [],
      ipList: [],
      FlowLineDataByClient: [],
      FlowLineDataByServer: [],
      flowTable: [],
      line_head: {
        name: '||Min||Max||Avg',
        type: 'line',
        data: [],
        itemStyle: {
          color: '#00000000',
        },
        lineStyle: {
          color: '#00000000',
        },
        showSymbol: false,
      },
      flowList: {},
      showRule: [
        {
          prop: 'App_Protocol',
          label: 'App',
          width: '120px',
          subComponent: JSON.parse(localStorage.getItem('userInfo')) ? DynamicRoute : null,
          rowFormat: row => {
            return [`/app_view/${row.App_Protocol}`, row.App_Protocol]
          },
        },
        // 隐藏protocol
        // {
        //   prop: 'Master_Protocol',
        //   label: 'Protocol',
        //   width: '120px',
        // },
        {
          prop: 'Src_IP',
          label: 'Client IP',
          width: '120px',
          subComponent: JSON.parse(localStorage.getItem('userInfo')) ? DynamicRoute : null,
          rowFormat: row => {
            return [`/client_view/${row.Src_IP}`, row.Src_IP]
          },
        },
        {
          prop: 'Dst_IP',
          label: 'Server IP',
          width: '120px',
          subComponent: JSON.parse(localStorage.getItem('userInfo')) ? DynamicRoute : null,
          rowFormat: row => {
            return [`/client_view/${row.Dst_IP}`, row.Dst_IP]
          },
        },
        {
          prop: 'First_Seen',
          label: 'First Seen',
          width: '180px',
          sortable: 'custom',
          compare: (a, b) => new Date(a.First_Seen) - new Date(b.First_Seen),
        },
        {
          prop: 'Last_Seen',
          label: 'Last Seen',
          width: '180px',
          sortable: 'custom',
          compare: (a, b) => new Date(a['Last Seen']) - new Date(b['Last Seen']),
        },
        {
          prop: 'Dst_Port',
          label: 'Server Port',
          width: '100px',
          keepOriginal: true,
        },
        {
          prop: 'Src_Port',
          label: 'Client Port',
          width: '100px',
          keepOriginal: true,
        },
        {
          prop: 'Src_to_Dst_pps',
          label: 'Client to Server pps',
          width: '130px',
          // fixed: 'right',
          sortable: 'custom',
          compare: (a, b) => a.Src_to_Dst_pps - b.Src_to_Dst_pps,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_pps
          },
        },
        {
          prop: 'Src_to_Dst_bps',
          label: 'Client to Server bps',
          width: '130px',
          // fixed: 'right',
          sortable: 'custom',
          compare: (a, b) => a.Src_to_Dst_bps - b.Src_to_Dst_bps,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Src_to_Dst_bps
          },
        },
        {
          prop: 'Dst_to_Src_pps',
          label: 'Server to Client pps',
          width: '130px',
          // fixed: 'right',
          sortable: 'custom',
          compare: (a, b) => a.Dst_to_Src_pps - b.Dst_to_Src_pps,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_pps
          },
        },
        {
          prop: 'Dst_to_Src_bps',
          label: 'Server to Client bps',
          width: '130px',
          // fixed: 'right',
          sortable: 'custom',
          compare: (a, b) => a.Dst_to_Src_bps - b.Dst_to_Src_bps,
          subComponent: NumberCol,
          rowFormat: row => {
            return row.Dst_to_Src_bps
          },
        },
      ],
      DataZone: parseInt(new Date().getTimezoneOffset() / 60),
      apiOptionsMap: {
        '/api': 'CERNET',
        '/bdapi': 'BDREN',
        '/learnapi': 'LEARN',
      },
    }
  },
  props: {},

  mounted() {},
  methods: {
    searchIpList(queryString, cb) {
      const ipList = this.ipList
      const results = queryString ? ipList.filter(ip => ip.value.indexOf(queryString) === 0) : ipList
      cb(results.slice(0, this.ipListLimit))
    },
    async getIpListByLimit() {
      const ipList = await getIpListByApp(
        this.ipVersion,
        this.appName,
        ...this.dateArray,
        this.ipListLimit,
        this.baseURL
      )
      this.ipList = ipList.map(ip => {
        return { value: ip }
      })
    },

    searchByIp() {
      this.getFlowDetailTable()
      this.getFlowDetailLine()
    },
    async getFlowDetailTable() {
      this.loading = true
      // console.debug('this.appName', JSON.stringify(this.appName))
      const res = await getFlowDetailTable(
        this.ipVersion,
        ...this.dateArray,
        this.appName,
        this.clientIp,
        this.baseURL
      ).catch(() => {
        this.loading = false
      })
      this.handleFlowTable(res)
      this.loading = false
    },

    async getFlowDetailLine() {
      // console.debug('this.appName', JSON.stringify(this.appName))
      const res = await getFlowDetailLine(this.ipVersion, ...this.dateArray, this.appName, this.clientIp, this.baseURL)

      this.handleLineData(res)
    },

    handleFlowTable({ flow_table }) {
      this.flowTable = flow_table.map(item => {
        const date = new Date(item['First_Seen'] - this.DataZone * 60 * 60 * 1000)
        // console.debug(date)
        item['First_Seen'] = formatData(date)
        // console.debug(item['First_Seen'])
        if (!item['Last_Seen']) {
          item['Last_Seen'] = item['First_Seen']
        } else {
          const lastDate = new Date(item['Last_Seen'] - this.DataZone * 60 * 60 * 1000)
          item['Last_Seen'] = formatData(lastDate)
        }
        if (item['Dst_Port'] === -1) {
          item['Dst_Port'] = 'N/A'
        }

        if (item['Src_Port'] === -1) {
          item['Src_Port'] = 'N/A'
        }
        return item
      })
    },
    handleLineData({ line_data_client, line_data_server }) {
      if (line_data_client.length !== 0) {
        this.FlowLineDataByClient = line_data_client.map(item => {
          item.type = 'line'
          // console.debug(item.data)
          item.data = item.data.filter(d => d[1] !== 0).map(this.transformDateWithTimeZone)
          item.showSymbol = item.data.length < 10 ? true : false
          return item
        })
        this.FlowLineDataByClient.push(this.line_head)
      } else {
        this.FlowLineDataByClient = []
      }

      if (line_data_server.length !== 0) {
        this.FlowLineDataByServer = line_data_server.map(item => {
          item.type = 'line'
          // console.debug(item.data)
          item.data = item.data.filter(d => d[1] !== 0).map(this.transformDateWithTimeZone)
          item.showSymbol = item.data.length < 10 ? true : false
          return item
        })
        this.FlowLineDataByServer.push(this.line_head)
      } else {
        this.FlowLineDataByServer = []
      }
    },
    transformDateWithTimeZone(lineitem) {
      lineitem[0] = lineitem[0] - this.DataZone * 60 * 60 * 1000
      return lineitem
    },
    async getAppList() {
      const appList = await getAppList(this.ipVersion, ...this.dateArray, this.baseURL)
      if (!this.$route.query.appName) {
        this.appName = appList[0]
      }
      this.appOption = appList.map((app, index) => {
        return {
          value: index,
          label: app,
        }
      })
    },
    async dataChange(dateArray, DataZone) {
      this.dateArray = dateArray
      this.DataZone = DataZone
      this.getAppList().then(() => {
        this.getIpListByLimit()
      })
      this.getFlowDetailTable()
      this.getFlowDetailLine()
    },
    selectDateZone(newDateZone, dateArray) {
      this.DataZone = newDateZone
      this.dateArray = dateArray
      this.getAppList().then(() => {
        this.getIpListByLimit()
      })
      this.getFlowDetailTable()
      this.getFlowDetailLine()
    },

    async appChange(appIndex) {
      this.appName = this.appOption[appIndex].label
      this.clientIp = ''
      this.FlowLineDataByClient = []
      this.FlowLineDataByServer = []
      this.flowTable = []
      this.getIpListByLimit()
    },
  },
}
</script>

<style lang="scss" scoped>
.selector {
  padding-right: 20px;
  width: calc(100% - 20px);
}

.col {
  background-color: #fff;
}

.line {
  width: 100%;
  height: 50vh;
  padding: 5px;
}
</style>
